.footer {
  @include themed() {
    background-color: t($white-color);
    line-height: t($line-height);

    nav > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-weight: normal;

      a:not(.btn) {
        color: t($dark-gray);
        display: block;
        margin-bottom: 3px;
        &:hover,
        &:focus {
          color: t($default-states-color);
        }
      }
    }
    .social-area {
      padding: 15px 0;
      h5 {
        padding-bottom: 15px;
      }
    }
    .social-area > a:not(.btn) {
      color: t($dark-gray);
      display: inline-block;
      vertical-align: top;
      padding: t($padding-social-a);
      font-size: t($font-size-large-navbar);
      font-weight: normal;
      line-height: t($line-height);
      text-align: center;
      &:hover,
      &:focus {
        color: t($default-states-color);
      }
    }
    .copyright {
      color: t($default-states-color);
      padding: 10px 15px;
      margin: 10px 3px;
      line-height: 20px;
      font-size: t($font-size-base);
    }
    hr {
      border-color: t($medium-gray);
    }
    .title {
      color: t($default-states-color);
    }
  }
}

.footer-default {
  @include themed() {
    background-color: t($smoke-bg);
  }
}

.footer:not(.footer-big) {
  nav > ul {
    @include themed() {
      font-size: t($font-size-base);
      li {
        margin-left: 20px;
        float: left;
      }
      a {
        padding: 10px 0px;
        margin: 10px 10px 10px 0px;
      }
    }
  }
}
