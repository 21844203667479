.root :global(.dropdown) {
    pointer-events: initial;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background-color: white;
}

.root :global(.dropdown__content) {
    display: none;
    position: absolute;
    z-index: 999;
}

.root :global(.dropdown--active) :global(.dropdown__content) {
    display: block;
}

.root :global(.dropdown__trigger:after) {
    content: '\25BE';
    margin: 0 3px 0 5px;
    font-size: x-small;
}

.root ul {
    list-style: none;
    padding: 1px 3px;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
}

.items {
    background-color: white;
    border: 1px solid black;
    width: max-content;
}

.root li:hover {
    background-color: lightblue;
}

