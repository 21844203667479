.root {
}

.title {
    font-size: large;
}

.note {
    margin: 0.5em;
    border: 1px solid #aaa;
    padding: 0.5em;
}

.noteNote {
    border: 1px dashed #aaa;
    padding: 0.5em;
    min-height: 1em;
}

.noteFooter {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
}

.noteAuthor {
    margin-right: 0.5em;
    font-weight: bold;
}

.noteCreatedAt {
    margin-right: 1em;
}
