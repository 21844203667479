html {
  --textcolor: #ddd;
}

/* // dark theme */
html[data-theme="dark"] {
  --textcolor: white;
}


.utilityHead {
  padding: 15px;
}

.utilityHead span {
  font-size: 20px;
  font-weight: bold;
  color: var(--textcolor);
}
