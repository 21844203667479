.alert {
  @include themed() {
    border: 0;
    border-radius: 0;
    color: t($white-color);
    padding: 10px 15px;
    font-size: 14px;

    .container & {
      border-radius: 4px;
    }
    .navbar & {
      border-radius: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 85px;
      width: 100%;
      z-index: 3;
    }
    .navbar:not(.navbar-transparent) & {
      top: 70px;
    }

    span[data-notify="icon"] {
      font-size: 30px;
      display: block;
      left: 15px;
      position: absolute;
      top: 50%;
      margin-top: -15px;
    }

    button.close {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -13px;
      z-index: 1033;
      background-color: t($white-color);
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      line-height: 20px;
      font-size: 12px;
      width: 25px;
      height: 25px;
      outline: 0 !important;
      text-align: center;
      padding: 3px;
      font-weight: 300;

      &:hover {
        opacity: 0.55;
      }
    }

    .close ~ span {
      display: block;
      max-width: 89%;
    }

    &[data-notify="container"] {
      padding: 10px 10px 10px 20px;
      border-radius: t($border-radius-base);
    }

    &.alert-with-icon {
      padding-left: 65px;
    }
    a,
    a:hover,
    a:focus {
      text-decoration: underline;
      color: t($white-color);
    }
  }
}
.alert-info {
  @include themed() {
    background-color: t($azure-navbar);
  }
}
.alert-success {
  @include themed() {
    background-color: t($green-navbar);
  }
}
.alert-warning {
  @include themed() {
    background-color: t($orange-navbar);
  }
}
.alert-danger {
  @include themed() {
    background-color: t($red-navbar);
  }
}
