.root {
    display: flex;
    flex-direction: row;
    margin: 1em;
}

.left {
    width: 20em;
}

.right {
    flex: 1 1 auto;
    margin-left: 1em;
}

.license {
  white-space: pre;
}

