.toolbox {
    position: sticky;
    left: 1px;
    top: 4px;
    z-index: 1;
    font-size: smaller;
    display: inline-block;
}

.zoomPercentage {
    margin-left: 3px;
}

.label {
    position: absolute;
    left: 0;
    top: -1.3em;
    margin-left: -3px;
    padding-left: 2px;
    font-weight: bold;
}

.labelNote {
    position: absolute;
    left: 0;
    margin-left: -3px;
    background-color: yellow;
    padding-left: 2px;
    padding-right: 2px;
    white-space: pre-line;
    pointer-events: initial;
}

.region {
    pointer-events: none;
}

.modes {
    display: inline-block;
    margin-left: 2em;
}
.selectedMode {
    background: lightskyblue;
}