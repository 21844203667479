.selectedItem {
    background: lightcyan;
}

.item {
    cursor: pointer;
    padding: 1px 2px 4px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.itemCommands {
    float: right;
    font-size: smaller;
}

.itemDelete {
    background-color: pink;
    vertical-align: middle;
    padding-left: 2px;
    padding-right: 2px;
}

.itemShowHide {
    background-color: white;
    margin-left: 3px;
    vertical-align: middle;
    padding-left: 2px;
    padding-right: 2px;
}

.itemCrop {
    background-color: yellow;
    margin-left: 3px;
    vertical-align: middle;
    padding-left: 2px;
    padding-right: 2px;
}

.itemNote {
    margin-top: 1px;
    width: 100%;
    min-height: 1.5em;
    border: 1px solid #aaa;
    padding: 0.1em;
    background: white;
    color: black;
}

.user {
    font-size: large;
    margin-top: 5px;
}

.frames {
    background: white;
}

.showBoxesLabel {
    margin-left: 10px;
}

.showBoxesCheckbox {
    vertical-align: text-bottom;
}

.classificationItems {
    display: flex;
    flex-wrap: wrap;
}

.classificationItem {
    font-weight: bold;
    color: white;
    padding: 3px 5px;
    border-radius: 7px;
    margin: 1px 3px 1px 1px;
}

.classificationItem i {
    border-radius: 50%;
    margin-left: 3px;
}
