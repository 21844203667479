.root {
}

.folder {
  display: flex;
  margin: 2px;
}

.folderName {
  margin-left: 3px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}