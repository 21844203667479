html {
  --color: black;
  --trEvenBg: #e2e2e2;
  --trOddBg: #ddd;
  --thBg: #687076;
}

/* // dark theme */
html[data-theme="dark"] {
  --color: #f5deb3;
  --trEvenBg: #788086;
  --trOddBg: #54595e;
  --thBg: #54595e;
}

.root {
  padding-left: 15px;
  padding-right: 15px;
}

.items {
    display: flex;
    flex-wrap: wrap;
}

.item {
    margin: 0 0 7px;
    padding: 12px 40px;
    border-radius: 4px;
    cursor: pointer;
    background: #1DC7EA;
    border: 1px solid transparent;
    font-size: 18px;
    color: #fff;
}

.container {
    display: flex;
    position: relative;
    margin-right: 8px;
}

.container :global(.fa) {
    position: absolute;
    font-size: 16px;
    background: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    line-height: 18px;
    top: -5px;
    border-width: 1px;
    border-color: gray;
    border-style: solid;
}

.container :global(.fa-times-circle) {
    right: -5px;
    color: red;
}

.container :global(.fa-check-circle) {
    right: -5px;
    color: green;
}

.delete {
    -webkit-align-self: flex-start;
    align-self: flex-start;
    background-color: #fff;
    margin-left: -20px;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    height: 18px;
}

:global(.swatches-picker) svg {
    height: 20px !important;
    position: absolute;
    top: 2px;
}

.buttons {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.buttons button {
  font-size: large;
  margin: 5px;
}

.buttons button:disabled {
  color: white;
  cursor: not-allowed;
}


.infoBox {
    color: var(--color);
    text-align: center;
    font-weight: bold;
    margin: 5px;
}
