html {
  --color: black;
  --trEvenBg: #e2e2e2;
  --trOddBg: #ddd;
  --thBg: #687076;
  --bordercolor: #ddd;
}

/* // dark theme */
html[data-theme="dark"] {
  --color: #f5deb3;
  --trEvenBg: #788086;
  --trOddBg: #54595e;
  --thBg: #54595e;
  --bordercolor: white;
}


.statsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statsBox div:first-child h4 {
    margin: 0;
}


.statTable {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 10px;
}

.statTable td,
.statTable th {
    border: 1px solid var(--bordercolor);
    padding: 8px 14px;
    text-align: left;
    font-size: 0.8rem;
    color: var(--color);
}

.statTable th {
    font-weight: 700;
    font-size: 0.8rem;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, sans-serif;
    text-transform: uppercase;
    margin: 0;
    max-width: 800px;
}

.statTable tr:nth-child(even) {
  background-color: var(--trEvenBg);
}

.statTable tr:hover {
  background-color: var(--trOddBg);
}

.statTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--thBg);
  color: white;
}

.preLine {
    white-space: pre-line;
}