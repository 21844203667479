:global(.sidebar) {
  transition: transform 0.6s ease-in;
  transform: translateX(0);
  z-index: 1;
}

:global(.sidebar) :global(.nav) :global(.sub-nav) {
  margin-top: 5px;
  padding-left: 15px;
}

:global(.sidebar) :global(.nav) :global(.sub-nav) li a {
  padding: 3px 10px;
  background: rgba(0, 0, 0, 0.28);
}

:global(.sidebar) :global(.nav) :global(.sub-nav) li:last-child {
  margin-bottom: 10px;
}

.node {
  white-space: nowrap;
  overflow: hidden;
}