.root {
}

.row {
    display: flex;
    margin-bottom: 0.5em;
}

.rowCaption {
    font-size: larger;
    margin-left: 1em;
}

.range {
    margin: 1em 5em 2em;
}

.fps {
    border: green 1px solid;
    background: lightgreen;
    border-radius: 3px;
    padding: 1px 2px;
    margin: 3px;
    cursor: pointer;
}

.currentFps {
    background: lightskyblue;
    font-weight: bold;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}
