.form-control::-moz-placeholder {
  @include themed() {
    @include placeholder(t($medium-gray), 1);
  }
}
.form-control:-moz-placeholder {
  @include themed() {
    @include placeholder(t($medium-gray), 1);
  }
}
.form-control::-webkit-input-placeholder {
  @include themed() {
    @include placeholder(t($medium-gray), 1);
  }
}
.form-control:-ms-input-placeholder {
  @include themed() {
    @include placeholder(t($medium-gray), 1);
  }
}

.form-control {
  @include themed() {
    background-color: t($white-bg);
    border: 1px solid t($light-gray);
    border-radius: t($border-radius-base);
    color: t($form-control);
    @include input-size(
      t($padding-base-vertical),
      t($padding-base-horizontal) - 4,
      t($height-base)
    );
    @include box-shadow(none);

    &:focus {
      background-color: t($white-bg);
      border: 1px solid t($medium-dark-gray);
      @include box-shadow(none);
      outline: 0 !important;
      color: t($black-color);
    }

    .has-success &,
    .has-error &,
    .has-success &:focus,
    .has-error &:focus {
      border-color: t($light-gray);
      @include box-shadow(none);
    }

    .has-success & {
      color: t($success-color);
    }
    .has-success &:focus {
      border-color: t($success-color);
    }
    .has-error & {
      color: t($danger-color);
    }
    .has-error &:focus {
      border-color: t($danger-color);
    }

    & + .form-control-feedback {
      border-radius: t($border-radius-large);
      font-size: t($font-size-base);
      margin-top: -7px;
      position: absolute;
      right: 10px;
      top: 50%;
      vertical-align: middle;
    }

    .open & {
      border-radius: t($border-radius-base) t($border-radius-base) 0 0;
      border-bottom-color: transparent;
    }
  }
}

.input-lg {
  @include themed() {
    height: 55px;
    padding: t($padding-large-vertical) t($padding-large-horizontal);
  }
}

.has-error {
  .form-control-feedback {
    @include themed() {
      color: t($danger-color);
    }
  }
}
.has-success {
  .form-control-feedback {
    @include themed() {
      color: t($success-color);
    }
  }
}

.input-group-addon {
  @include themed() {
    background-color: t($white-color);
    border: 1px solid t($light-gray);
    border-radius: t($border-radius-base);

    .has-success &,
    .has-error & {
      background-color: t($white-color);
      border: 1px solid t($light-gray);
    }
    .has-error .form-control:focus + & {
      border-color: t($danger-color);
      color: t($danger-color);
    }
    .has-success .form-control:focus + & {
      border-color: t($success-color);
      color: t($success-color);
    }
    .form-control:focus + &,
    .form-control:focus ~ & {
      background-color: t($white-color);
      border-color: t($dark-gray);
    }
  }
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  @include themed() {
    background-color: t($smoke-bg);
    color: t($default-color);
    cursor: not-allowed;
  }
}

.input-group-btn .btn {
  @include themed() {
    border-width: t($border-thin);
    padding: t($padding-round-vertical) t($padding-base-horizontal);
  }
}
.input-group-btn .btn-default:not(.btn-fill) {
  @include themed() {
    border-color: t($medium-gray);
  }
}

.input-group-btn:last-child > .btn {
  margin-left: 0;
}

.input-group-focus .input-group-addon {
  @include themed() {
    border-color: t($dark-gray);
  }
}
