.nav {
  > li {
    > a:hover,
    > a:focus {
      background-color: transparent;
    }
  }
}
.navbar {
  @include themed() {
    border: t($none);
    font-size: t($font-size-navbar);
    border-radius: 0;

    .navbar-brand {
      font-weight: t($font-weight-normal);
      margin: t($navbar-margin-brand);
      padding: t($navbar-padding-brand);
      font-size: t($font-size-large-navbar);
    }
    .navbar-nav {
      > li > a {
        padding: t($navbar-padding-a);
        margin: t($navbar-margin-a);
        position: relative;
      }
      > li > a.btn {
        margin: t($navbar-margin-a-btn);
        padding: t($padding-base-vertical) t($padding-base-horizontal);
      }
      > li > a.btn-round {
        margin: t($navbar-margin-a-btn-round);
      }
      > li > a [class^="fa"] {
        font-size: t($font-size-large) + 1;
        position: relative;
        line-height: 16px;
        top: 1px;
      }

      .notification {
        position: absolute;
        background-color: t($new-red);
        text-align: center;
        border-radius: 10px;
        min-width: 18px;
        padding: 0 5px;
        height: 18px;
        font-size: 12px;
        color: t($white-color);
        font-weight: bold;
        line-height: 18px;
        top: 0px;
        left: 7px;
      }
    }
    .btn {
      margin: t($navbar-margin-btn);
      font-size: t($font-size-base);
    }
    .btn-simple {
      font-size: t($font-size-medium);
    }
    .caret {
      // @include center-item();
    }

    &.fixed {
      width: calc(100% - #{t($sidebar-width)});
      right: 0;
      left: auto;
      border-radius: 0;
    }
  }
}
.navbar-nav > li > .dropdown-menu {
  @include themed() {
    border-radius: t($border-radius-extreme);
    margin-top: -5px;
  }
}

.navbar-transparent,
[class*="navbar-ct"] {
  @include themed() {
    .navbar-brand {
      color: t($white-color);
      @include opacity(0.9);

      &:focus,
      &:hover {
        background-color: transparent;
        @include opacity(1);
      }
    }

    .navbar-nav {
      > li > a:not(.btn) {
        color: t($white-color);
        border-color: t($white-color);
        @include opacity(0.8);
      }
      > .active > a:not(.btn),
      > .active > a:hover:not(.btn),
      > .active > a:focus:not(.btn),
      > li > a:hover:not(.btn),
      > li > a:focus:not(.btn) {
        background-color: transparent;
        border-radius: 3px;
        color: t($white-color);
        @include opacity(1);
      }
      .nav > li > a.btn:hover {
        background-color: transparent;
      }

      > .dropdown > a .caret,
      > .dropdown > a:hover .caret,
      > .dropdown > a:focus .caret {
        border-bottom-color: t($white-color);
        border-top-color: t($white-color);
      }

      > .open > a,
      > .open > a:hover,
      > .open > a:focus {
        background-color: transparent;
        color: t($white-color);
        @include opacity(1);
      }
    }

    .btn-default {
      color: t($white-color);
      border-color: t($white-color);
    }
    .btn-default.btn-fill {
      color: t($dark-gray);
      background-color: t($white-color);
      @include opacity(0.9);
    }
    .btn-default.btn-fill:hover,
    .btn-default.btn-fill:focus,
    .btn-default.btn-fill:active,
    .btn-default.btn-fill.active,
    .open .dropdown-toggle.btn-fill.btn-default {
      border-color: t($white-color);
      @include opacity(1);
    }
  }
}
.navbar-transparent {
  @include themed() {
    .dropdown-menu .divider {
      background-color: rgbaColor(t($white-color), 0.2);
    }
  }
}

.nav-open .nav .caret {
  @include themed() {
    border-bottom-color: t($white-color);
    border-top-color: t($white-color);
  }
}

.navbar-default {
  @include themed() {
    background-color: t($white-navbar);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .navbar-nav {
      > li > a:not(.btn) {
        color: t($dark-gray);
      }

      > .active > a,
      > .active > a:not(.btn):hover,
      > .active > a:not(.btn):focus,
      > li > a:not(.btn):hover,
      > li > a:not(.btn):focus {
        background-color: transparent;
        border-radius: 3px;
        color: t($info-color);
        @include opacity(1);
      }

      > .dropdown > a:hover .caret,
      > .dropdown > a:focus .caret {
        border-bottom-color: t($info-color);
        border-top-color: t($white-color);
      }

      > .open > a,
      > .open > a:hover,
      > .open > a:focus {
        background-color: transparent;
        color: t($info-color);
      }

      .navbar-toggle:hover,
      .navbar-toggle:focus {
        background-color: transparent;
      }
    }

    &:not(.navbar-transparent) .btn-default:hover {
      color: t($info-color);
      border-color: t($info-color);
    }
    &:not(.navbar-transparent) .btn-neutral,
    &:not(.navbar-transparent) .btn-neutral:hover,
    &:not(.navbar-transparent) .btn-neutral:active {
      color: t($dark-gray);
    }
  }
}

/*      Navbar with icons            */

.navbar-icons {
  @include themed() {
    &.navbar .navbar-brand {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .navbar-nav {
      > li > a {
        text-align: center;
        padding: t($navbar-padding-a-icons);
        margin: t($navbar-margin-a-icons);
      }

      [class^="pe"] {
        font-size: 30px;
        position: relative;
      }
      p {
        margin: 3px 0 0;
      }
    }
  }
}

.navbar-form {
  @include themed() {
    @include box-shadow(none);
    .form-control {
      @include light-form();
      height: 22px;
      font-size: t($font-size-navbar);
      line-height: t($line-height-general);
      color: t($light-gray);
    }
    .navbar-transparent & .form-control,
    [class*="navbar-ct"] & .form-control {
      color: t($white-color);
      border: t($none);
      border-bottom: 1px solid rgbaColor(t($white-color), 0.6);
    }
  }
}

.navbar-ct-blue {
  @include themed() {
    @include navbar-color(t($blue-navbar));
  }
}
.navbar-ct-azure {
  @include themed() {
    @include navbar-color(t($azure-navbar));
  }
}
.navbar-ct-green {
  @include themed() {
    @include navbar-color(t($green-navbar));
  }
}
.navbar-ct-orange {
  @include themed() {
    @include navbar-color(t($orange-navbar));
  }
}
.navbar-ct-red {
  @include themed() {
    @include navbar-color(t($red-navbar));
  }
}

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.navbar-toggle {
  @include themed() {
    margin-top: 19px;
    margin-bottom: 19px;
    border: t($none);

    .icon-bar {
      background-color: t($white-color);
    }
    .navbar-collapse,
    .navbar-form {
      border-color: transparent;
    }

    &.navbar-default .navbar-toggle:hover,
    &.navbar-default .navbar-toggle:focus {
      background-color: transparent;
    }
  }
}
