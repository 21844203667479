//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
$none: "none";
$border-thin: "border-thin";
$border-thick: "border-thick";

$white-color: "white-color";
$white-bg: "white-bg";

$smoke-bg: "smoke-bg";
$off-white: "off-white";
$panel-bg: "panel-bg";

// $black-bg:                   rgba(30,30,30,.97) !default;

$black-color: "black-color";
// $black-hr:                   #444444 !default;

$light-gray: "light-gray";
$medium-gray: "medium-gray";
$medium-dark-gray: "medium-dark-gray";
$dark-gray: "dark-gray";

$transparent-bg: "transparent-bg";

$default-color: "default-color";
// $default-bg:                 #888888 !default;
$default-states-color: "default-states-color";

$primary-color: "primary-color";
// $primary-bg:                 #3472F7 !default;
$primary-states-color: "primary-states-color";

$success-color: "success-color";
// $success-bg:                 #87CB16 !default;
$success-states-color: "success-states-color";

$info-color: "info-color";
// $info-bg:                    #1DC7EA !default;
$info-states-color: "info-states-color";

$warning-color: "warning-color";
// $warning-bg:                 #FF9500 !default;
$warning-states-color: "warning-states-color";

$danger-color: "danger-color";
// $danger-bg:                  #FF4A55 !default;
$danger-states-color: "danger-states-color";

// $link-disabled-color:        #666666 !default;

/*      light colors         */
$light-blue: "light-blue";
$light-azure: "light-azure";
$light-green: "light-green";
$light-orange: "light-orange";
$light-red: "light-red";

//== Components
//

$padding-base-vertical: "padding-base-vertical";
$padding-base-horizontal: "padding-base-horizontal";

$padding-round-vertical: "padding-round-vertical";
$padding-round-horizontal: "padding-round-horizontal";

$padding-simple-vertical: "padding-simple-vertical";
$padding-simple-horizontal: "padding-simple-horizontal";

$padding-large-vertical: "padding-large-vertical";
$padding-large-horizontal: "padding-large-horizontal";

$padding-small-vertical: "padding-small-vertical";
$padding-small-horizontal: "padding-small-horizontal";

$padding-xs-vertical: "padding-xs-vertical";
$padding-xs-horizontal: "padding-xs-horizontal";

$padding-label-vertical: "padding-label-vertical";
$padding-label-horizontal: "padding-label-horizontal";

$margin-large-vertical: "margin-large-vertical";
$margin-base-vertical: "margin-base-vertical";

$padding-zero: "padding-zero";

$margin-bottom: "margin-bottom";
$border-radius-small: "border-radius-small";
$border-radius-base: "border-radius-base";
$border-radius-large: "border-radius-large";
$border-radius-extreme: "border-radius-extreme";

$border-radius-large-top: "border-radius-large-top";
$border-radius-large-bottom: "border-radius-large-bottom";

$btn-round-radius: "btn-round-radius";

$height-base: "height-base";

$font-size-base: "font-size-base";
$font-size-small: "font-size-small";
$font-size-medium: "font-size-medium";
$font-size-large: "font-size-large";
$font-size-large-navbar: "font-size-large-navbar";

$font-size-h1: "font-size-h1";
$font-size-h2: "font-size-h2";
$font-size-h3: "font-size-h3";
$font-size-h4: "font-size-h4";
$font-size-h5: "font-size-h5";
$font-size-h6: "font-size-h6";
$font-paragraph: "font-paragraph";
$font-size-navbar: "font-size-navbar";
$font-size-small: "font-size-small";

$font-weight-light: "font-weight-light";
$font-weight-normal: "font-weight-normal";
$font-weight-semi: "font-weight-semi";
$font-weight-bold: "font-weight-bold";

$line-height-general: "line-height-general";
$line-height: "line-height";
$line-height-lg: "line-height-lg";

$sidebar-width: "sidebar-width";

$border-radius-top: "border-radius-top";
$border-radius-bottom: "border-radius-bottom";

$dropdown-shadow: "dropdown-shadow";

$general-transition-time: "general-transition-time";

$slow-transition-time: "slow-transition-time";
$dropdown-coordinates: "dropdown-coordinates";

$fast-transition-time: "fast-transition-time";

$ultra-fast-transition-time: "ultra-fast-transition-time";

$select-coordinates: "select-coordinates";

$transition-linear: "transition-linear";
$transition-bezier: "transition-bezier";
$transition-ease: "transition-ease";
$transition-ease-in: "transition-ease-in";
$transition-ease-out: "transition-ease-out";

$navbar-padding-a: "navbar-padding-a";
$navbar-margin-a: "navbar-margin-a";

$padding-social-a: "padding-social-a";

$navbar-margin-a-btn: "navbar-margin-a-btn";
$navbar-margin-a-btn-round: "navbar-margin-a-btn-round";

$navbar-padding-a-icons: "navbar-padding-a-icons";
$navbar-margin-a-icons: "navbar-margin-a-icons";

$navbar-padding-brand: "navbar-padding-brand";
$navbar-margin-brand: "navbar-margin-brand";

$navbar-margin-brand-icons: "navbar-margin-brand-icons";

$navbar-margin-btn: "navbar-margin-btn";

$height-icon: "height-icon";
$width-icon: "width-icon";
$padding-icon: "padding-icon";
$border-radius-icon: "border-radius-icon";

$size-icon: "size-icon";
$size-icon-sm: "size-icon-sm";

$height-icon-sm: "height-icon-sm";
$width-icon-sm: "width-icon-sm";
$padding-icon-sm: "padding-icon-sm";
$border-radius-icon-sm: "border-radius-icon-sm";

$height-icon-message: "height-icon-message";
$width-icon-message: "width-icon-message";

$height-icon-message-sm: "height-icon-message-sm";
$width-icon-message-sm: "width-icon-message-sm";

$default-color-top: "default-color-top";
$default-color-bottom: "default-color-bottom";

// $blue-color-top: #4087ea;
$blue-color-bottom: "blue-color-bottom";

// $azure-color-top: #45c0fd;
$azure-color-bottom: "azure-color-bottom";

// $green-color-top: #a1eb3a;
$green-color-bottom: "green-color-bottom";

// $orange-color-top: #ffb33b;
$orange-color-bottom: "orange-color-bottom";

// $red-color-top: #ff3b30;
$red-color-bottom: "red-color-bottom";

// $purple-color-top: #df55e1;
$purple-color-bottom: "purple-color-bottom";

// $pink-color-top: #ff2a63;
// $pink-color-bottom: #ff2e2e;

$black-color-top: "black-color-top";
$black-color-bottom: "black-color-bottom";

$social-facebook: "social-facebook";
$social-twitter: "social-twitter";
$social-pinterest: "social-pinterest";
$social-google: "social-google";
$social-linkedin: "social-linkedin";
$social-dribbble: "social-dribbble";
$social-github: "social-github";
$social-youtube: "social-youtube";
$social-stumbleupon: "social-stumbleupon";
$social-reddit: "social-reddit";
$social-tumblr: "social-tumblr";
$social-behance: "social-behance";

// $filter-blue: darken($primary-color, 10%);
// $filter-azure: darken($info-color, 10%);
// $filter-green: darken($success-color, 10%);
// $filter-orange: darken($warning-color, 10%);
// $filter-red: darken($danger-color, 10%);

$new-blue: "new-blue";
$new-purple: "new-purple";
$new-red: "new-red";
$new-green: "new-green";
$new-orange: "new-orange";
$new-dark-blue: "new-dark-blue";
$new-black: "new-black";

$topbar-x: "topbar-x";
$topbar-back: "topbar-back";
$bottombar-x: "bottombar-x";
$bottombar-back: "bottombar-back";

$white-navbar: "white-navbar";
$blue-navbar: "blue-navbar";
$azure-navbar: "azure-navbar";
$green-navbar: "green-navbar";
$orange-navbar: "orange-navbar";
$red-navbar: "red-navbar";

///new
$side-bar: "side-bar";
$cb-primary: "cb-primary";
$cb-danger: "cb-danger";
$cb-info: "cb-info";
$cb-warning: "cb-warning";
$cb-success: "cb-success";
$cb-default: "cb-default";

$switch-focused: "switch-focused";

$border-gray: "border-gray";

$dw-li-a: "dw-li-a";

$form-control: "form-control";

$side-bar-bg: "side-bar-bg";
$side-bar-active: "side-bar-active";

$sidebar-menu-text: "sidebar-menu-text";
$drawer-border: "drawer-border";

$text-color: "text-color";

$themes: (
  default: (
    $none: "0 ",
    $border-thin: 1px,
    $border-thick: 2px,
    $white-color: #ffffff,
    $white-bg: #ffffff,
    $smoke-bg: #f5f5f5 n,
    $off-white: #eeeeee,
    $panel-bg: rgba(203, 203, 210, 0.15),
    $black-color: #333333,
    $light-gray: #e3e3e3,
    $medium-gray: #dddddd,
    $medium-dark-gray: #aaaaaa,
    $dark-gray: #9a9a9a,
    $transparent-bg: transparent,
    $default-color: #888888,
    $default-states-color: #777777,
    $primary-color: #3472f7,
    $primary-states-color: #1d62f0,
    $success-color: #87cb16,
    $success-states-color: #049f0c,
    $info-color: #1dc7ea,
    $info-states-color: lighten(#1dc7ea, 8%),
    $warning-color: #ff9500,
    $warning-states-color: #ed8d00,
    $danger-color: #ff4a55,
    $danger-states-color: #ee2d20,
    $light-blue: rgba(#3472f7, 0.2),
    $light-azure: rgba(#1dc7ea, 0.2),
    $light-green: rgba(#87cb16, 0.2),
    $light-orange: rgba(#ff9500, 0.2),
    $light-red: rgba(#ff4a55, 0.2),
    $padding-base-vertical: 8px,
    $padding-base-horizontal: 16px,
    $padding-round-vertical: 9px,
    $padding-round-horizontal: 18px,
    $padding-simple-vertical: 10px,
    $padding-simple-horizontal: 18px,
    $padding-large-vertical: 14px,
    $padding-large-horizontal: 30px,
    $padding-small-vertical: 5px,
    $padding-small-horizontal: 10px,
    $padding-xs-vertical: 1px,
    $padding-xs-horizontal: 5px,
    $padding-label-vertical: 2px,
    $padding-label-horizontal: 12px,
    $margin-large-vertical: 30px,
    $margin-base-vertical: 15px,
    $padding-zero: 0px,
    $margin-bottom: 0 0 10px 0,
    $border-radius-small: 3px,
    $border-radius-base: 4px,
    $border-radius-large: 6px,
    $border-radius-extreme: 10px,
    $border-radius-large-top: $border-radius-large $border-radius-large 0 0,
    $border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large,
    $btn-round-radius: 30px,
    $height-base: 40px,
    $font-size-base: 14px,
    $font-size-small: 12px,
    $font-size-medium: 16px,
    $font-size-large: 18px,
    $font-size-large-navbar: 20px,
    $font-size-h1: 52px,
    $font-size-h2: 36px,
    $font-size-h3: 28px,
    $font-size-h4: 22px,
    $font-size-h5: 16px,
    $font-size-h6: 14px,
    $font-paragraph: 16px,
    $font-size-navbar: 16px,
    $font-weight-light: 300,
    $font-weight-normal: 400,
    $font-weight-semi: 500,
    $font-weight-bold: 600,
    $line-height-general: 1.5,
    $line-height: 20px,
    $line-height-lg: 54px,
    $sidebar-width: calc(100% - 260px),
    $border-radius-top: 10px 10px 0 0,
    $border-radius-bottom: 0 0 10px 10px,
    $dropdown-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125),
    $general-transition-time: 300ms,
    $slow-transition-time: 370ms,
    $dropdown-coordinates: 29px -50px,
    $fast-transition-time: 150ms,
    $ultra-fast-transition-time: 100ms,
    $select-coordinates: 50% -40px,
    $transition-linear: linear,
    $transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1),
    $transition-ease: ease 0s,
    $transition-ease-in: ease-in,
    $transition-ease-out: ease-out,
    $navbar-padding-a: 10px 15px,
    $navbar-margin-a: 10px 3px,
    $padding-social-a: 10px 5px,
    $navbar-margin-a-btn: 15px 3px,
    $navbar-margin-a-btn-round: 16px 3px,
    $navbar-padding-a-icons: 6px 15px,
    $navbar-margin-a-icons: 6px 3px,
    $navbar-padding-brand: 15px 15px,
    $navbar-margin-brand: 5px 0px,
    $navbar-margin-brand-icons: 12px auto,
    $navbar-margin-btn: 15px 3px,
    $height-icon: 64px,
    $width-icon: 64px,
    $padding-icon: 12px,
    $border-radius-icon: 15px,
    $size-icon: 64px,
    $size-icon-sm: 32px,
    $height-icon-sm: 32px,
    $width-icon-sm: 32px,
    $padding-icon-sm: 4px,
    $border-radius-icon-sm: 7px,
    $height-icon-message: 40px,
    $width-icon-message: 40px,
    $height-icon-message-sm: 20px,
    $width-icon-message-sm: 20px,
    $default-color-top: #d9d9d9,
    $default-color-bottom: #909297,
    $blue-color-bottom: #2632c1,
    $azure-color-bottom: #4091ff,
    $green-color-bottom: #6dc030,
    $orange-color-bottom: #ff5221,
    $red-color-bottom: #bb0502,
    $purple-color-bottom: #943bea,
    $black-color-top: #282828,
    $black-color-bottom: #111,
    $social-facebook: #3b5998,
    $social-twitter: #55acee,
    $social-pinterest: #cc2127,
    $social-google: #dd4b39,
    $social-linkedin: #0976b4,
    $social-dribbble: #ea4c89,
    $social-github: #333333,
    $social-youtube: #e52d27,
    $social-stumbleupon: #eb4924,
    $social-reddit: #ff4500,
    $social-tumblr: #35465c,
    $social-behance: #1769ff,
    $new-blue: #1dc7ea,
    $new-purple: #9368e9,
    $new-red: #fb404b,
    $new-green: #87cb16,
    $new-orange: #ffa534,
    $new-dark-blue: #1b8dff,
    $new-black: #5e5e5e,
    $topbar-x: topbar-x,
    $topbar-back: topbar-back,
    $bottombar-x: bottombar-x,
    $bottombar-back: bottombar-back,
    $white-navbar: rgba(#ffffff, 0.96),
    $blue-navbar: lighten(#1b8dff, 10%),
    $azure-navbar: lighten(#1dc7ea, 15%),
    $green-navbar: lighten(#87cb16, 10%),
    $orange-navbar: lighten(#ffa534, 10%),
    $red-navbar: lighten(#fb404b, 10%),
    $side-bar: #1d2b36,
    $cb-primary: #428bca,
    $cb-danger: #d9534f,
    $cb-info: #5bc0de,
    $cb-warning: #f0ad4e,
    $cb-success: #5cb85c,
    $cb-default: #eeeeee,
    $switch-focused: #66afe9,
    $border-gray: #ccc,
    $dw-li-a: #333333,
    $form-control: #565656,
    $side-bar-bg: #999,
    $side-bar-active: #84aecf,
    $sidebar-menu-text: white,
    $drawer-border: rgba(255, 255, 255, 0.2),
    $text-color: #11191f,
  ),
  dark: (
    $none: 0,
    $border-thin: 1px,
    $border-thick: 2px,
    $white-color: rgb(30, 30, 30),
    $white-bg: rgb(30, 30, 30),
    $smoke-bg: rgb(23, 22, 23) n,
    $off-white: rgb(23, 22, 23),
    $panel-bg: $white-bg,
    $black-color: #fefefe,
    $light-gray: #e3e3e3,
    $medium-gray: #dddddd,
    $medium-dark-gray: #aaaaaa,
    $dark-gray: #9a9a9a,
    $transparent-bg: transparent,
    $default-color: #fefefe,
    $default-states-color: #777777,
    $primary-color: #3472f7,
    $primary-states-color: #1d62f0,
    $success-color: #87cb16,
    $success-states-color: #049f0c,
    $info-color: #f5deb3,
    $info-states-color: lighten(#f5deb3, 8%),
    $warning-color: #ff9500,
    $warning-states-color: #ed8d00,
    $danger-color: #ff4a55,
    $danger-states-color: #ee2d20,
    $light-blue: rgba(#3472f7, 0.2),
    $light-azure: rgba(#f5deb3, 0.2),
    $light-green: rgba(#87cb16, 0.2),
    $light-orange: rgba(#ff9500, 0.2),
    $light-red: rgba(#ff4a55, 0.2),
    $padding-base-vertical: 8px,
    $padding-base-horizontal: 16px,
    $padding-round-vertical: 9px,
    $padding-round-horizontal: 18px,
    $padding-simple-vertical: 10px,
    $padding-simple-horizontal: 18px,
    $padding-large-vertical: 14px,
    $padding-large-horizontal: 30px,
    $padding-small-vertical: 5px,
    $padding-small-horizontal: 10px,
    $padding-xs-vertical: 1px,
    $padding-xs-horizontal: 5px,
    $padding-label-vertical: 2px,
    $padding-label-horizontal: 12px,
    $margin-large-vertical: 30px,
    $margin-base-vertical: 15px,
    $padding-zero: 0px,
    $margin-bottom: 0 0 10px 0,
    $border-radius-small: 3px,
    $border-radius-base: 4px,
    $border-radius-large: 6px,
    $border-radius-extreme: 10px,
    $border-radius-large-top: $border-radius-large $border-radius-large 0 0,
    $border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large,
    $btn-round-radius: 30px,
    $height-base: 40px,
    $font-size-base: 14px,
    $font-size-small: 12px,
    $font-size-medium: 16px,
    $font-size-large: 18px,
    $font-size-large-navbar: 20px,
    $font-size-h1: 52px,
    $font-size-h2: 36px,
    $font-size-h3: 28px,
    $font-size-h4: 22px,
    $font-size-h5: 16px,
    $font-size-h6: 14px,
    $font-paragraph: 16px,
    $font-size-navbar: 16px,
    $font-weight-light: 300,
    $font-weight-normal: 400,
    $font-weight-semi: 500,
    $font-weight-bold: 600,
    $line-height-general: 1.5,
    $line-height: 20px,
    $line-height-lg: 54px,
    $sidebar-width: calc(100% - 260px),
    $border-radius-top: 10px 10px 0 0,
    $border-radius-bottom: 0 0 10px 10px,
    $dropdown-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125),
    $general-transition-time: 300ms,
    $slow-transition-time: 370ms,
    $dropdown-coordinates: 29px -50px,
    $fast-transition-time: 150ms,
    $ultra-fast-transition-time: 100ms,
    $select-coordinates: 50% -40px,
    $transition-linear: linear,
    $transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1),
    $transition-ease: ease 0s,
    $transition-ease-in: ease-in,
    $transition-ease-out: ease-out,
    $navbar-padding-a: 10px 15px,
    $navbar-margin-a: 10px 3px,
    $padding-social-a: 10px 5px,
    $navbar-margin-a-btn: 15px 3px,
    $navbar-margin-a-btn-round: 16px 3px,
    $navbar-padding-a-icons: 6px 15px,
    $navbar-margin-a-icons: 6px 3px,
    $navbar-padding-brand: 15px 15px,
    $navbar-margin-brand: 5px 0px,
    $navbar-margin-brand-icons: 12px auto,
    $navbar-margin-btn: 15px 3px,
    $height-icon: 64px,
    $width-icon: 64px,
    $padding-icon: 12px,
    $border-radius-icon: 15px,
    $size-icon: 64px,
    $size-icon-sm: 32px,
    $height-icon-sm: 32px,
    $width-icon-sm: 32px,
    $padding-icon-sm: 4px,
    $border-radius-icon-sm: 7px,
    $height-icon-message: 40px,
    $width-icon-message: 40px,
    $height-icon-message-sm: 20px,
    $width-icon-message-sm: 20px,
    $default-color-top: #d9d9d9,
    $default-color-bottom: #909297,
    $blue-color-bottom: #2632c1,
    $azure-color-bottom: #4091ff,
    $green-color-bottom: #6dc030,
    $orange-color-bottom: #ff5221,
    $red-color-bottom: #bb0502,
    $purple-color-bottom: #943bea,
    $black-color-top: #282828,
    $black-color-bottom: #111,
    $social-facebook: #3b5998,
    $social-twitter: #55acee,
    $social-pinterest: #cc2127,
    $social-google: #dd4b39,
    $social-linkedin: #0976b4,
    $social-dribbble: #ea4c89,
    $social-github: #333333,
    $social-youtube: #e52d27,
    $social-stumbleupon: #eb4924,
    $social-reddit: #ff4500,
    $social-tumblr: #35465c,
    $social-behance: #1769ff,
    $new-blue: #f5deb3,
    $new-purple: #9368e9,
    $new-red: #fb404b,
    $new-green: #87cb16,
    $new-orange: #ffa534,
    $new-dark-blue: #1b8dff,
    $new-black: #5e5e5e,
    $topbar-x: topbar-x,
    $topbar-back: topbar-back,
    $bottombar-x: bottombar-x,
    $bottombar-back: bottombar-back,
    $white-navbar: rgba(#ffffff, 0.96),
    $blue-navbar: lighten(#1b8dff, 10%),
    $azure-navbar: lighten(#f5deb3, 15%),
    $green-navbar: lighten(#87cb16, 10%),
    $orange-navbar: lighten(#ffa534, 10%),
    $red-navbar: lighten(#fb404b, 10%),
    $side-bar: rgb(14, 12, 14),
    $cb-primary: #428bca,
    $cb-danger: #d9534f,
    $cb-info: #f5deb3,
    $cb-warning: #f0ad4e,
    $cb-success: #5cb85c,
    $cb-default: #eeeeee,
    $switch-focused: #66afe9,
    $border-gray: #ccc,
    $dw-li-a: #333333,
    $form-control: #fefefe,
    $side-bar-bg: #999,
    $side-bar-active: #ac8959,
    $sidebar-menu-text: wheat,
    $drawer-border: black,
    $text-color: #f5deb3,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

// @function lighten($color, $percentage) {
//   @return lighten($color, $percentage);
// }

// @function rgba($rgb, $opacity) {
//   @return rgba($rgb, $opacity);
// }
