.sidebar {
  @include themed() {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: t($white-color);
    font-weight: 200;
    background-size: cover;
    border-right: 2px solid t($drawer-border);
    background-position: center center;

    .sidebar-wrapper {
      position: relative;
      height: calc(100vh - 75px);
      overflow: auto;
      width: 260px;
      z-index: 4;
      padding-bottom: 30px;
    }

    .sidebar-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
      background: t($side-bar);
    }

    .logo {
      padding: 10px 30px;
      border-bottom: 1px solid t($drawer-border);
      position: relative;
      z-index: 4;

      p {
        float: left;
        font-size: 20px;
        margin: 10px 10px;
        color: t($white-color);
        line-height: 20px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .logo-mini {
        float: left;
        text-align: center;
        width: 30px;
        margin-right: 15px;

        img {
          color: t($sidebar-menu-text);
          height: 34px;
          width: 34px;
          margin-left: -3px;
          display: block;
          margin-top: 2px;
        }
      }

      .logo-normal {
        display: block;
      }

      .logo-img {
        width: 34px;
        display: inline-block;
        height: 34px;
        margin-left: -2px;
        margin-top: -2px;
        margin-right: 10px;
        border-radius: 30px;
        text-align: center;
        color: t($sidebar-menu-text);
      }
    }

    .logo-tim {
      border-radius: 50%;
      border: 1px solid t($black-color);
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
      }
    }

    .nav {
      margin-top: 20px;

      li {
        > a {
          color: t($white-color);
          opacity: 0.86;
        }

        &:hover > a,
        &.open > a,
        &.open > a:focus,
        &.open > a:hover {
          background: rgba(255, 255, 255, 0.13);
          opacity: 1;
        }

        &.active > a {
          color: t($white-color);
          opacity: 1;
          background: rgba(0, 0, 0, 0.23);
          border-right: 5px solid t($side-bar-active);
        }
      }

      p {
        color: t($sidebar-menu-text);
        margin: 0;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .caret {
        top: 24px;
        position: absolute;
        right: 15px;
      }

      i {
        color: t($sidebar-menu-text);
        font-size: 28px;
        float: left;
        margin-right: 15px;
        line-height: 30px;
        width: 30px;
        text-align: center;
      }
    }
  }
}

.sidebar,
body > .navbar-collapse {
  @include themed() {
    .logo {
      padding: 10px 30px;
      border-bottom: 1px solid t($drawer-border);

      p {
        float: left;
        font-size: 20px;
        margin: 10px 10px;
        color: t($white-color);
        line-height: 20px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .simple-text {
        text-transform: uppercase;
        padding: t($padding-small-vertical) t($padding-zero);
        display: block;
        font-size: t($font-size-medium);
        // color: $white-color;
        color: t($sidebar-menu-text);
        font-weight: t($font-weight-bold);
        font-family: "Arial";
        line-height: 30px;
      }
    }

    .logo-tim {
      border-radius: 50%;
      border: 1px solid t($black-color);
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
      }
    }

    &:after,
    &:before {
      content: "";
    }

    &:after {
      @include icon-gradient(t($black-color-top), t($black-color-bottom));
      z-index: 3;
      opacity: 1;
    }

    &[data-image]:after,
    &.has-image:after {
      opacity: 0.77;
    }

    &[data-color="blue"]:after {
      @include icon-gradient(t($new-dark-blue), t($blue-color-bottom));
    }
    &[data-color="azure"]:after {
      @include icon-gradient(t($new-blue), t($azure-color-bottom));
    }
    &[data-color="green"]:after {
      @include icon-gradient(t($new-green), t($green-color-bottom));
    }
    &[data-color="orange"]:after {
      @include icon-gradient(t($new-orange), t($orange-color-bottom));
    }
    &[data-color="red"]:after {
      @include icon-gradient(t($new-red), t($red-color-bottom));
    }
    &[data-color="purple"]:after {
      @include icon-gradient(t($new-purple), t($purple-color-bottom));
    }
  }
}

.main-panel {
  @include themed() {
    background: t($white-bg);
    position: relative;
    float: right;
    width: t($sidebar-width);
    transition: transform 0.6s ease-in;
    transform: translateX(260px);
    > .content {
      padding: 30px 15px;
      min-height: calc(100% - 123px);
    }

    > .footer {
      border-top: 1px solid t($off-white);
    }

    .navbar {
      margin-bottom: 0;
    }
  }
}

.main-panel-close {
  @include themed() {
    background: t($panel-bg);
    position: relative;
    float: right;
    width: t($sidebar-width);
    transition: transform 0.6s ease-in;
    transform: translateX(0px);
    > .content {
      padding: 30px 15px;
      min-height: calc(100% - 123px);
    }

    > .footer {
      border-top: 1px solid t($off-white);
    }

    .navbar {
      margin-bottom: 0;
    }
  }
}

// .sidebar,
// .main-panel {
//   -webkit-transition-property: top, bottom;
//   transition-property: top, bottom;
//   -webkit-transition-duration: 0.2s, 0.2s;
//   transition-duration: 0.2s, 0.2s;
//   -webkit-transition-timing-function: linear, linear;
//   transition-timing-function: linear, linear;
//   -webkit-overflow-scrolling: touch;
// }

.closeDrawer {
  @include themed() {
    font-weight: 200;
    background-size: cover;
    background-position: center center;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    position: fixed;
    width: 260px;
    z-index: 1;
    // height: 100vh;
    border-right: 2px solid t($drawer-border);
    transform: translateX(-100%);
    transition: transform ease-in 0.6s;

    .sidebar-wrapper {
      position: relative;
      height: calc(100vh - 75px);
      overflow: auto;
      width: 260px;
      z-index: 4;
      padding-bottom: 30px;
      // margin-top: 11px;
    }

    .sidebar-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
      background: t($side-bar);
    }

    .logo {
      padding: 10px 30px;
      border-bottom: 1px solid t($drawer-border);
      position: relative;
      z-index: 4;

      p {
        float: left;
        font-size: 20px;
        margin: 10px 10px;
        color: t($white-color);
        line-height: 20px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .logo-mini {
        float: left;
        text-align: center;
        width: 30px;
        margin-right: 15px;

        img {
          color: t($sidebar-menu-text);
          height: 34px;
          width: 34px;
          margin-left: -3px;
          display: block;
          margin-top: 2px;
        }
      }

      .logo-normal {
        display: block;
      }

      .logo-img {
        color: t($sidebar-menu-text);
        width: 34px;
        display: inline-block;
        height: 34px;
        margin-left: -2px;
        margin-top: -2px;
        margin-right: 10px;
        border-radius: 30px;
        text-align: center;
      }
    }

    .logo-tim {
      border-radius: 50%;
      border: 1px solid t($black-color);
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
      }
    }

    .nav {
      margin-top: 20px;

      li {
        > a {
          color: t($white-color);
          opacity: 0.86;
        }

        &:hover > a,
        &.open > a,
        &.open > a:focus,
        &.open > a:hover {
          background: rgba(255, 255, 255, 0.13);
          opacity: 1;
        }

        &.active > a {
          color: t($white-color);
          opacity: 1;
          background: rgba(0, 0, 0, 0.23);
          border-right: 5px solid t($side-bar-active);
        }
      }

      p {
        color: t($sidebar-menu-text);
        margin: 0;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .caret {
        top: 24px;
        position: absolute;
        right: 15px;
      }

      i {
        color: t($sidebar-menu-text);
        font-size: 28px;
        float: left;
        margin-right: 15px;
        line-height: 30px;
        width: 30px;
        text-align: center;
      }
    }
  }
}
.closeDrawer,
body > .navbar-collapse {
  @include themed() {
    .logo {
      padding: 10px 30px;
      border-bottom: 1px solid t($drawer-border);

      p {
        float: left;
        font-size: 20px;
        margin: 10px 10px;
        color: t($white-color);
        line-height: 20px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .simple-text {
        text-transform: uppercase;
        padding: t($padding-small-vertical) t($padding-zero);
        display: block;
        font-size: t($font-size-medium);
        // color: $white-color;
        color: t($sidebar-menu-text);
        font-weight: t($font-weight-bold);
        font-family: "Arial";
        line-height: 30px;
      }
    }

    .logo-tim {
      border-radius: 50%;
      border: 1px solid t($black-color);
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
      }
    }

    &:after,
    &:before {
      content: "";
    }

    &:after {
      @include icon-gradient(t($black-color-top), t($black-color-bottom));
      z-index: 3;
      opacity: 1;
    }

    &[data-image]:after,
    &.has-image:after {
      opacity: 0.77;
    }

    &[data-color="blue"]:after {
      @include icon-gradient(t($new-dark-blue), t($blue-color-bottom));
    }
    &[data-color="azure"]:after {
      @include icon-gradient(t($new-blue), t($azure-color-bottom));
    }
    &[data-color="green"]:after {
      @include icon-gradient(t($new-green), t($green-color-bottom));
    }
    &[data-color="orange"]:after {
      @include icon-gradient(t($new-orange), t($orange-color-bottom));
    }
    &[data-color="red"]:after {
      @include icon-gradient(t($new-red), t($red-color-bottom));
    }
    &[data-color="purple"]:after {
      @include icon-gradient(t($new-purple), t($purple-color-bottom));
    }
  }
}

// .menuhandler {
//   z-index: 1;
//   display: block;
//   position: fixed;
//   top: 150px;
//   left: 0px;
//   height: 40px;
//   width: 50px;
//   transition: transform 0.6s ease-in;
//   transform: translateX(0%);
// }

// .menuhandler-close {
//   z-index: 1;
//   display: block;
//   position: fixed;
//   top: 150px;
//   left: 0px;
//   height: 40px;
//   width: 50px;
//   transition: transform 0.6s ease-in;
//   transform: translateX(520%);
// }
