.root {
}

.parameter {
    display: flex;
}

.name {
    width: 150px;
}

.percentage {
    width: 180px;
    display: flex;
}

.percentageRange {
    width: 150px;
}

.percentageValue {
    margin-left: 10px;
}

.root :global(.input-range__label) {
    display: none;
}

.root :global(.input-range__track--background) {
    margin-top: 0;
}

.float input {
    width: 6em;
}