.root {
}

.buttons {
  font-size: large;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.buttons button {
  font-size: large;
  margin: 5px;
}
