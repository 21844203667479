.search {
  margin-left: 0.5em;
}

.search i {
  font-weight: bold;
  font-size: 17px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.search a {
  padding: 10px 18px 10px;
  background: #1dc7ea !important;
  color: #fff !important;
  border-radius: 3px;
  text-decoration: none;
  font-size: 17px;
  margin: 12px 0 0;
}

.modal {
  width: 90%;
}