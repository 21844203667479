html[data-theme="dark"] :global(.modal-content) {
    background-color: rgb(30, 30, 30);
}

.root {
    font-size: large;
    max-height: 90vh;
}

.buttons {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.root button {
    font-size: large;
    margin-left: 5px;
    margin-right: 5px;
}

.root button:disabled {
    color: gray;
    cursor: not-allowed;
}

.root textarea {
    resize: none;
    height: 10em;
}
