.folderBox {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 4px;
  height: 70px;
  margin-bottom: 20px;
}

.icon {
  width: 50px;
}

.icon i {
  font-size: 52px;
  color: #fff;
}

.subFolderBox {
  flex: 1 1 auto;
  text-align: center;
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.subFolderBox h5 {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #fff;
  font-weight: 600;
  word-break: break-word;
}

.commands {
  width: 50px;
  color: #fff;
  text-align: right;
}
