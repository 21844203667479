.root {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 10px;
}

.area {
    flex: 1 1 auto;
    resize: none;
}

.buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.button {
    margin-left: 5px;
    font-size: large;
}
