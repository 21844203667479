.root,
.root:focus {
  text-transform: capitalize;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 15px #d0d0d0;
  cursor: pointer;
  display: inline-block;
  margin: 2px 10px 2px 2px;
  border: none;
  background: #1dc7ea;
  /* font-size: 15px; */
  color: #fff;
  /* font-weight: 600; */
  opacity: 1;
}

:global(.btn-info) :local(.caret) {
  border-top-color: white;
}

html[data-theme="dark"] .root, .root:focus {
  box-shadow: 0 0 15px black;
  background: #f5deb3;
  color: black;
}

html[data-theme="dark"] :global(.btn-info) :local(.caret) {
  border-top-color: black;
}
