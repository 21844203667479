.root {
    padding-left: 15px;
    padding-right: 15px;
    overflow: auto;
    max-width: 100%;
    max-height: calc(100vh - 100px);
}

.table {
    border-collapse: collapse;
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
}

.table td, .table th {
    border: 1px solid #ddd;
    padding: 8px 14px;
    text-align: left;
    font-size: 1rem;
}

.table th {
    font-weight: 700;
    font-size: 0.8rem;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, sans-serif;
    margin: 0;
    max-width: 800px;
}

.table thead th {
    text-transform: uppercase;
}

.table tr:nth-child(even) {
    background-color: #e2e2e2;
}

.table tr:hover {
    background-color: #ddd;
}

.table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #687076;
    color: white;
}

.table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table thead th:first-child {
    left: -15px;
    z-index: 2;
}

.table tbody th {
    position: sticky;
    left: -15px;
    z-index: 1;
}

.cellApplication {
    width: 30em;
}

.cellKey {
    width: 30em;
}

.cellEnable {
    width: 80px;
}

.cellCommands {
    width: 200px;
}
