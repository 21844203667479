body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

* {
    box-sizing: border-box;
}

html {
    font-size: 14px;
}