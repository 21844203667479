html {
  --textcolor: #ddd;
}

/* // dark theme */
html[data-theme="dark"] {
  --textcolor: white;
}

.imgOverlay {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s;
    margin-bottom: 30px;
    clear: both;
}

.overlay {
    position: absolute;
    content: '';
    background: linear-gradient(transparent, #00000082);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.overlay:hover {
    z-index: 1;
    background: #000000d6;
    transition: all 0.3s;
}

.textBottom {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 10px;
    text-align: left;
    padding-left: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

.imgOverlay img {
    width: 100%;
    height: 320px;
}

.imgOverlay:hover img {
    transform: scale(1.1);
    transition: all 0.3s;
}

.overlay:hover .textBottom {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: unset;
    text-align: center;
    padding-left: unset;
    font-weight: 600;
    letter-spacing: 1px;
    margin: auto;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    left: 0;
}

.imgOverlay:hover .overlay .textBottom .keepReading {
    display: block;
}

.keepReading {
    display: none;
}

.keepReading span {
    transition-property: opacity;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 0s;
    font-weight: 600;
    color: #0099e6;
    font-size: 12px;
}

.description {
    font-size: 12px;
    line-height: unset;
}

.buttonIcons {
    float: right;
    font-size: 20px;
}

.buttonIcon {
    margin: 3px 5px;
    cursor: pointer;
}

.buttonIcon:global(.fa-pencil) {
    color: dodgerblue;
}

.buttonIcon:global(.fa-times) {
    color: red;
}

.visibility {
    display: inline-flex;
}

.visibilityLabel {
    margin-right: 5px;
    color: var(--textcolor);
}
