.root {
}

.line {
    display: flex;
    margin-bottom: 3px;
}

.plain {
    flex: 0 0 auto;
    margin-right: 3px;
}

.textLabel {
    flex: 0 0 auto;
    margin-right: 3px;
}

.text {
    flex: 1 1 auto;
    margin-left: 3px;
    border: 1px solid lightgray;
}

.check {
    margin-right: 5px;
}

.checks {
    display: flex;
    flex-wrap: wrap;
}

.controlCheck {
    display: flex;
    flex: 1 1 50%;
    margin-bottom: 2px;
}

.controlText {
    display: flex;
    flex: 1 1 auto;
}