.root {
}

.pdfTable {
    border-collapse: collapse;
    width: 100%;

}

.pdfTable td, .pdfTable th {
    border: 1px solid #ddd;
    padding: 8px 14px;
    text-align: left;
    font-size: 1rem;
}

.pdfTable th {
    font-weight: 700;
    font-size: 0.8rem;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, sans-serif;
    text-transform: uppercase;
    margin: 0;
    max-width: 800px;
}

.pdfTable tr:nth-child(even) {
    background-color: #e2e2e2;
}

.pdfTable tr:hover {
    background-color: #ddd;
}

.pdfTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #687076;
    color: white;
}

.commands {
    width: 200px;
}