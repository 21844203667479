html {
  --color: black;
  --trEvenBg: #e2e2e2;
  --trOddBg: #ddd;
  --thBg: #687076;
}

/* // dark theme */
html[data-theme="dark"] {
  --color: #f5deb3;
  --trEvenBg: #788086;
  --trOddBg: #54595e;
  --thBg: #54595e;
}

.root {
  padding-left: 15px;
  padding-right: 15px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  border: 1px solid #ddd;
  padding: 8px 14px;
  text-align: left;
  font-size: 1rem;
  color: var(--color);
}

.table th {
  font-weight: 700;
  font-size: 0.8rem;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, sans-serif;
  text-transform: uppercase;
  margin: 0;
  max-width: 800px;
  line-height: inherit;
}

.table tr:nth-child(even) {
  background-color: var(--trEvenBg);
}

.table tr:hover {
  background-color: var(--trOddBg);
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--thBg);
  color: white;
}

.counter {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

h4,
h3 {
  color: var(--color);
}
