.root {
    font-size: large;
    max-height: 90vh;
}

.item {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.verticalItem {
    flex-direction: column;
    overflow-y: auto;
    max-height: 50vh;
}

.itemLabel {
    margin-right: 10px;
    white-space: nowrap;
    min-width: 90px;
}

.checkboxLabel {
    margin-left: 10px;
}

.buttons {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.root button {
    font-size: large;
    margin-left: 5px;
    margin-right: 5px;
}

.root button:disabled {
    color: gray;
    cursor: not-allowed;
}

.title {
    width: 100%;
    height: 60px;
    resize: none;
    border-width: 2px;
}

.itemImage {
    text-decoration: underline;
    cursor: pointer;
}

.itemPreview {
    max-height: 20vh;
    max-width: 100%;
}

.itemImageDelete {
    color: red;
}

.images {
    display: flex;
    flex-wrap: wrap;
}

.image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3px;
    width: 150px;
    height: 170px;
    position: relative;
    border: 1px solid lightgray;
    cursor: pointer;
}

.imageHorizontal {
    width: 100%;
}

.imageVertical {
    height: calc(100% - 2em);
}

.imageCheckbox {
    position: absolute;
    top: 3px;
    left: 3px;
}

.imageTitle {
    font-size: small;
    max-height: 3em;
    overflow-y: hidden;
}

.root :global(.form-horizontal .form-group) {
    margin-right: 0;
}

.child {
    border-top: solid 1px lightgray;
    padding-top: 5px;
}

.childTitle {
    margin-top: 5px;
}