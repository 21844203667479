html {
  --ieTextColor: black;
  --listBg: azure;
  --listBorder: blue;
}

/* // dark theme */
html[data-theme="dark"] {
  --ieTextColor: #f5deb3;
  --listBg: rgb(20, 20, 20);
  --listBorder: azure;
}

.root {
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: 100%;
}

.editor {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-height: 100%;
}

.textRoot {
  display: flex;
  max-height: 100%;
}

.images {
  margin-top: 5px;
  flex: 1 1 auto;
  overflow: auto;
}

.detections {
  margin-bottom: 20px;
  border-bottom: 1px black solid;
}

.detection {
  margin-right: 5px;
  cursor: pointer;
  color: gray;
}

.header {
  font-size: large;
  margin-bottom: 10px;
  flex: 0 0 auto;
  color: var(--ieTextColor);
}

.rightPane {
  width: 300px;
  min-width: 300px;
  padding: 5px;
  top: 60px;
  right: 60px;
  background: var(--listBg);
  border: 1px solid var(--listBorder);
  overflow-y: auto;
  color: var(--ieTextColor);
}

.labelList {
  flex: 0 0 auto;
  font-size: small;
}

.title {
  margin-bottom: 5px;
  flex: 0 0 auto;
  color: var(--ieTextColor);
}

.titleCaption {
  font-weight: bold;
}

.action {
  font-size: small;
}
html[data-theme="dark"] .action {
  font-size: small;
  color: white;
}

.text {
  white-space: pre;
  flex-grow: 1;
  overflow: auto;
  border: 1px lightgray solid;
  padding: 0 3px;
  font-family: monospace;
}

.pdf {
  overflow-y: auto;
}

.workingCopy {
  float: right;
}

.workingCopyLink {
  margin-left: 5px;
  margin-right: 5px;
}

button {
  color: black;
}

input {
  color: black;
}

li {
  color: black;
}
