.root {
  height: 90vh;
}

.header {
  display: flex;
  margin: 3px;
}

.searchButton {
  height: 100%;
  margin-left: 1em;
}

.mediaCard {
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  margin-bottom: 10px;
}

.mediaCard .mediaImg {
  height: 250px;
  background: #ddd;
  position: relative;
}

.mediaCard .mediaImg img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.mediaCard .mediaInfo {
  display: flex;
  padding: 10px;
  align-items: center;
}

.mediaCard .mediaInfo h5 {
  color: var(--imgName);
  width: 100%;
  margin: 0;
}
