html {
  --linkColor: rgba(0, 0, 0, 0.5);
  --linkfocus: rgba(0, 0, 0, 0.5);
  --divider: #ddd;
  --imgName: black;
  --statColor: black;
}

/* // dark theme */
html[data-theme="dark"] {
  --linkColor: #a09b92;
  --linkfocus: #f7e6c7;
  --divider: #f5deb3;
  --imgName: #f3ab24;
  --statColor: white;
  background-color: rgb(30, 30, 30);
}

html[data-theme="dark"] h5 {
  color: #f5deb3;
}
html[data-theme="dark"] label {
  color: white;
}
html[data-theme="dark"] :local(.fa) {
  color: white;
}
html[data-theme="dark"] i {
  color: white;
}

.root {
}

.images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.progress {
  text-align: center;
}

.badges {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: small;
  font-weight: bold;
}

.badge {
  background: white;
  border: 1px solid;
  border-radius: 5px;
  padding: 2px;
  margin-left: 1px;
}

.links {
  padding: 5px;
  font-size: 16px;
  color: var(--linkColor);
}

a.links:hover {
  text-decoration: underline;
}

.links:focus {
  color: var(--linkfocus);
}

.mediaCard {
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  margin-bottom: 10px;
}

.mediaCard .mediaImg {
  height: 250px;
  background: #ddd;
  position: relative;
}

.mediaCard .mediaImg img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.mediaCard .mediaInfo {
  display: flex;
  padding: 10px;
  align-items: center;
}

.mediaCard .mediaInfo h5 {
  color: var(--imgName);
  width: 100%;
  margin: 0;
}

.divider {
  border-bottom: 1px solid var(--divider);
  margin-top: 20px;
  margin-bottom: 20px;
}

.folderOperation {
  margin-top: 10px;
}

.stat {
  margin-left: 20px;
  margin-right: 10px;
  font-weight: bold;
  color: var(--statColor);
}

.folderActions {
  display: flex;
  align-items: baseline;
}

.sortBySelect {
  margin-left: 0.5em;
}

.sortByTitle {
  margin-left: 3em;
}
