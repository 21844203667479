html {
  --rootBg: white;
  --headerColor: darkblue;
  --divider: #ddd;
  --white: white;
  --black: black;
}

/* // dark theme */
html[data-theme="dark"] {
  --rootBg: #1e1e1e;
  --headerColor: #f5deb3;
  --divider: #f5deb3;
  --white: black;
  --black: white;
}

.root {
  padding: 5px;
  background-color: var(--rootBg);
}

.errors {
  font-size: small;
  background-color: lightpink;
  margin-bottom: 10px;
}

.engines tr {
  text-align: center;
}

.engines td {
  padding: 1px;
}

.header {
  color: var(--headerColor);
  font-size: large;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  width: 400px;
}

html[data-theme="dark"] .input {
  width: 400px;
  color: black;
}

.administrators {
  width: 500px;
}

.enginesTitle {
  width: 200px;
}

html[data-theme="dark"] .enginesTitle {
  width: 200px;
  color: black;
}

html[data-theme="dark"] .enginesHost {
  width: 200px;
  color: black;
}

.buttons {
  margin-top: 20px;
}
html[data-theme="dark"] .select {
  color: black;
}

.button {
  margin-right: 10px;
}

.threeDAnnotUrl {
  width: 500px;
}

.threeDAnnot {
  display: flex;
}

.logoText {
  width: 500px;
}

.logoImage {
  text-decoration: underline;
  cursor: pointer;
}

.logoImagePreview {
  max-height: 20vh;
  max-width: 100%;
}

.logoImageDelete {
  color: red;
}

.retrainHost {
  width: 300px;
}

html[data-theme="dark"] .retrainHost {
  width: 300px;
  color: black;
}

:local(td) {
  color: var(--black);
}

:local(th) {
  color: var(--black);
}

.rangeContainer {
  width: 500px;
  display: flex;
  padding-left: 1em;
}

.range {
  width: 400px;
}

.rangeValue {
  margin-left: 10px;
}
