.root {
  margin: 15px;
}

.header {
  font-size: large;
  margin-bottom: 10px;
}

html[data-theme="dark"] .header {
  color: wheat;
  font-size: large;
  margin-bottom: 10px;
}

.folders {
  margin: 30px 0;
  background: #fff;
  padding: 30px 0;
  box-shadow: 0 0 20px #f5f5f5;
}

html[data-theme="dark"] .folders {
  margin: 30px 0;
  background: rgb(53, 51, 53);
  padding: 30px 0;
  box-shadow: 0 0 15px black;
}

.progress {
  text-align: center;
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.folderActions {
  display: flex;
  align-items: baseline;
}

.sortBySelect {
  margin-left: 0.5em;
}

.sortByTitle {
  margin-left: 1em;
}
