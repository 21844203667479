.root {
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.toggleButton {
    margin-top: 6px;
}

.image {
    text-decoration: underline;
    cursor: pointer;
}

.imageDelete {
    color: red;
}

.imagePreview {
    max-height: 20vh;
    max-width: 100%;
}

.root textarea {
    resize: none;
}