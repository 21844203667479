.root {
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.root textarea {
    resize: none;
    height: 200px;
}

.errors {
    color: red;
}