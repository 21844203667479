.root {
  font-size: large;
}

.buttons {
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.buttons button {
  font-size: large;
  margin: 5px;
}

.buttons button:disabled {
  color: white;
  cursor: not-allowed;
}

.engine {
  display: flex;
}

.engineName {
  margin-left: 10px;
}

.engineDisabled {
  color: gray;
}

.parameters {
  font-size: smaller;
  margin-left: 60px;
}

.groupWithSelectedEngines {
  font-weight: bold;
}

.groupHeader {
  font-size: 1.2em;
  cursor: pointer;
}

.groupTitle {
  margin-left: 0.4em;
}

.queuedJobs {
  color: navy;
  margin-left: 1em;
}
