// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
  @include themed() {
    border-color: $btn-color;
    color: $btn-color;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle {
      background-color: t($transparent-bg);
      color: $btn-states-color;
      border-color: $btn-states-color;
    }

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
      &,
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: t($transparent-bg);
        border-color: $btn-color;
      }
    }

    &.btn-fill {
      color: t($white-color);
      background-color: $btn-color;
      @include opacity(1);

      &:hover,
      &:focus,
      &:active,
      &.active,
      .open > &.dropdown-toggle {
        @include themed() {
          background-color: t($btn-states-color);
          color: t($white-color);
        }
      }

      .caret {
        @include themed() {
          border-top-color: t($white-color);
        }
      }
    }

    .caret {
      @include themed() {
        border-top-color: t($white-color);
      }
    }
  }
}

@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border) {
  font-size: $font-size;
  border-radius: $border;
  padding: $padding-vertical $padding-horizontal;

  &.btn-round {
    padding: $padding-vertical + 1 $padding-horizontal;
  }

  &.btn-simple {
    padding: $padding-vertical + 2 $padding-horizontal;
  }
}