html {
  --color: black;
  --trEvenBg: #e2e2e2;
  --trOddBg: #ddd;
  --thBg: #687076;
  --textcolor: black;
}

/* // dark theme */
html[data-theme="dark"] {
  --color: #f5deb3;
  --trEvenBg: #788086;
  --trOddBg: #54595e;
  --thBg: #54595e;
  --textcolor: white;
}

.root {
    padding-left: 15px;
    padding-right: 15px;
}

.action {
    display: flex;
    margin-bottom: 1em;
    color: var(--textcolor);
}

.actionTitle {
    margin-left: 1em;
}

.userTable {
    border-collapse: collapse;
    width: 100%;
}

.userTable td,
.userTable th {
    border: 1px solid #ddd;
    padding: 8px 14px;
    text-align: left;
    font-size: 1rem;
    color: var(--color);
}

.userTable th {
    font-weight: 700;
    font-size: 0.8rem;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, sans-serif;
    text-transform: uppercase;
    margin: 0;
    max-width: 800px;
}

.userTable tr:nth-child(even) {
  background-color: var(--trEvenBg);
}

.userTable tr:hover {
  background-color: var(--trOddBg);
}

.userTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--thBg);
  color: white;
}

.header {
  margin-right: 30px;
  color: var(--textcolor);
}

