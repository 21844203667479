html {
  --h1Border: #e5e5e5;
  --h1Bg: #1dc7ea;
}

/* // dark theme */
html[data-theme="dark"] {
  --h1Border: #f5deb3;
  --h1Bg: #f5deb3;
}

.settings {
  font-size: 22px;
  margin-bottom: 20px;
}

.settingsItem {
  margin-bottom: 5px;
  display: flex;
}

.settingsItemLabel {
  font-weight: bolder;
  margin-right: 10px;
}

html[data-theme="dark"] .settingsItemLabel {
  font-weight: bolder;
  margin-right: 10px;
  color: #f3e5ca;
}

.root {
  padding: 0 15px;
}

.root h1 {
  padding: 0 0 9px;
  font-size: 26px;
  border-bottom: 1px solid var(--h1Border);
  text-transform: uppercase;
  position: relative;
}

.root h1:before {
  position: absolute;
  width: 60px;
  height: 4px;
  content: "";
  background: var(--h1Bg);
  bottom: -2px;
}

.root a {
  color: var(--h1Bg);
  text-decoration: underline;
  font-size: 18px;
}
