/*      Checkbox and radio         */
.checkbox,
.radio {
  margin-bottom: 12px;
}

.checkbox label,
.radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  margin-bottom: 0;
}

.checkbox label::before,
.checkbox label::after {
  @include themed() {
    font-family: "FontAwesome";
    content: "\f096";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    cursor: pointer;
    line-height: 19px;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 1px;
    color: t($medium-gray);
    transition: color 0.2s linear;
    padding: 1px;
  }
}

.checkbox label::after {
  @include themed() {
    content: "";
    text-align: center;
    opacity: 1;
    left: 1px;
    color: t($medium-gray);
  }
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  margin-left: 0;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f046";
}

.checkbox input[type="checkbox"]:checked + label::after {
  color: $info-color;
}

.checkbox input[type="checkbox"]:checked + label::before {
  opacity: 0;
}

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label,
.checkbox input[type="checkbox"]:disabled:checked + label::after {
  @include themed() {
    color: t($medium-gray);
  }
}
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label::after {
  cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label {
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  padding-left: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  @include themed() {
    background-color: t($cb-primary);
    border-color: t($cb-primary);
  }
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  @include themed() {
    color: t($white-color);
  }
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  @include themed() {
    background-color: t($cb-danger);
    border-color: t($cb-danger);
  }
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  @include themed() {
    color: t($white-color);
  }
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  @include themed() {
    background-color: t($cb-info);
    border-color: t($cb-info);
  }
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  @include themed() {
    color: t($white-color);
  }
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  @include themed() {
    background-color: t($cb-warning);
    border-color: t($cb-warning);
  }
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  @include themed() {
    color: t($white-color);
  }
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  @include themed() {
    background-color: t($cb-success);
    border-color: t($cb-success);
  }
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  @include themed() {
    color: t($white-color);
  }
}

.radio label::before,
.radio label::after {
  @include themed() {
    font-family: "FontAwesome";
    content: "\f10c";
    font-size: 20px;
    height: 20px;
    width: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: absolute;
    line-height: 19px;
    left: 0;
    top: 0;
    color: t($medium-gray);
    padding: 1px;
    transition: color 0.2s linear;
  }
}

.radio input[type="radio"]:checked + label::after {
  @include themed() {
    font-family: "FontAwesome";
    content: "\f192";
    color: t($medium-gray);
  }
}

.radio input[type="radio"]:checked + label::after {
  @include themed() {
    color: t($info-color);
  }
}

.radio input[type="radio"]:disabled + label {
  @include themed() {
    color: t($medium-gray);
  }
}

.radio input[type="radio"]:disabled + label::before,
.radio input[type="radio"]:disabled + label::after {
  @include themed() {
    color: t($medium-gray);
  }
}

.radio.radio-inline {
  margin-top: 0;
}

/**
  * bootstrap-switch - Turn checkboxes and radio buttons into toggle switches.
  *
  * @version v3.3.4
  * @homepage https://bttstrp.github.io/bootstrap-switch
  * @author Mattia Larentis <mattia@larentis.eu> (http://larentis.eu)
  * @license Apache-2.0
  */

.bootstrap-switch {
  @include themed() {
    display: inline-block;
    direction: ltr;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid;
    border-color: t($border-gray);
    position: relative;
    text-align: left;
    overflow: hidden;
    line-height: 8px;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-transition: border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  @include themed() {
    color: t($white-color);
    background: t($cb-primary);
  }
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  @include themed() {
    color: t($white-color);
    background: t($cb-info);
  }
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  @include themed() {
    color: t($white-color);
    background: t($cb-success);
  }
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  @include themed() {
    background: t($cb-warning);
    color: t($white-color);
  }
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  @include themed() {
    color: t($white-color);
    background: t($cb-danger);
  }
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  @include themed() {
    color: #000;
    background: t($cb-default);
  }
}
.bootstrap-switch .bootstrap-switch-label {
  @include themed() {
    text-align: center;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 100;
    color: t($black-color);
    background: t($white-color);
  }
}
.bootstrap-switch span::before {
  content: "\200b";
}
.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-switch input[type="radio"],
.bootstrap-switch input[type="checkbox"] {
  position: absolute !important;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  -o-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-focused {
  border-color: $switch-focused;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off
  .bootstrap-switch-label {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on
  .bootstrap-switch-label {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
