.root {
}

.userTable {
    border-collapse: collapse;
    width: 100%;
}

.userTable td, .userTable th {
    border: 1px solid #ddd;
    padding: 8px 14px;
    text-align: left;
}

.userTable th {
    font-weight: 700;
    font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, sans-serif;
    text-transform: uppercase;
    margin: 0;
}

.userTable tr:nth-child(even) {
    background-color: #e2e2e2;
}

.userTable tr:hover {
    background-color: #ddd;
}

.userTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #687076;
    color: white;
}
