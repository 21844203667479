.root {
}

.note {
    margin: 0.5em;
    border: 1px solid #aaa;
    padding: 0.5em;
}

html[data-theme="dark"] .note {
    color: #f3e5ca;
}

.noteNote {
    border: 1px dashed #aaa;
    padding: 0.5em;
    min-height: 1em;
}

.unreadNote {
    font-weight: bold;
}

.noteFooter {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
}

.noteAuthor {
    margin-right: 0.5em;
    font-weight: bold;
}

.noteCreatedAt {
    margin-right: 1em;
}
