.card {
  @include themed() {
    border-radius: t($border-radius-base);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
    background-color: t($white-color);
    margin-bottom: 30px;

    .image {
      width: 100%;
      overflow: hidden;
      height: 260px;
      border-radius: t($border-radius-base) t($border-radius-base) 0 0;
      position: relative;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;

      img {
        width: 100%;
      }
    }
    .filter {
      position: absolute;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.68);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;

      @include opacity(0);

      .btn {
        @include vertical-align();
      }
    }
    &:hover .filter {
      @include opacity(1);
    }
    .btn-hover {
      @include opacity(0);
    }
    &:hover .btn-hover {
      @include opacity(1);
    }
    .content {
      padding: 15px 15px 10px 15px;
    }
    .header {
      padding: 15px 15px 0;
      background-color: red;
    }
    .category,
    label {
      font-size: t($font-size-base);
      font-weight: t($font-weight-normal);
      color: t($dark-gray);
      margin-bottom: 0px;

      i {
        font-size: t($font-paragraph);
      }
    }

    label {
      font-size: t($font-size-small);
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .title {
      margin: t($none);
      color: t($black-color);
      font-weight: t($font-weight-light);
    }
    .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 5px;
    }
    .description {
      font-size: t($font-size-base);
      color: t($black-color);
    }
    .footer {
      padding: 0;
      background-color: t($transparent-bg);
      line-height: 30px;

      .legend {
        padding: 5px 0;
      }

      hr {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .stats {
      color: t($medium-dark-gray);
    }
    .footer div {
      display: inline-block;
    }

    .author {
      font-size: t($font-size-small);
      font-weight: t($font-weight-bold);
      text-transform: uppercase;
    }
    .author i {
      font-size: t($font-size-base);
    }
    h6 {
      font-size: t($font-size-small);
      margin: 0;
    }
    &.card-separator:after {
      height: 100%;
      right: -15px;
      top: 0;
      width: 1px;
      background-color: t($medium-gray);
      content: "";
      position: absolute;
    }

    .ct-chart {
      margin: 30px 0 30px;
      height: 245px;
    }

    .table {
      tbody td:first-child,
      thead th:first-child {
        padding-left: 15px;
      }

      tbody td:last-child,
      thead th:last-child {
        padding-right: 15px;
      }
    }

    .alert {
      border-radius: t($border-radius-base);
      position: relative;

      &.alert-with-icon {
        padding-left: 65px;
      }
    }
  }
}
.card-user {
  @include themed() {
    .image {
      height: 110px;
    }
    .image-plain {
      height: 0;
      margin-top: 110px;
    }
    .author {
      text-align: center;
      text-transform: none;
      margin-top: -70px;
    }
    .avatar {
      width: 124px;
      height: 124px;
      border: 5px solid t($white-color);
      position: relative;
      margin-bottom: 15px;

      &.border-gray {
        border-color: t($off-white);
      }
    }
    .title {
      line-height: 24px;
    }
    .content {
      min-height: 240px;
    }
  }
}

.card-user,
.card-price {
  @include themed() {
    .footer {
      padding: 5px 15px 10px;
    }
    hr {
      margin: 5px 15px;
    }
  }
}

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;

  .image {
    border-radius: 4px;
  }
}

.card-stats {
  .icon-big {
    font-size: 3em;
    min-height: 64px;
    i {
      font-weight: bold;
      line-height: 59px;
    }
  }
  .numbers {
    font-size: 2em;
    text-align: right;
    p {
      margin: 0;
    }
  }
}
