.support {
  margin-left: 0.5em;
}

.support i {
  font-weight: bold;
  font-size: 17px;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.support a {
  padding: 10px 18px 10px;
  background: #1dc7ea !important;
  color: #fff !important;
  border-radius: 3px;
  text-decoration: none;
  font-size: 17px;
  margin: 12px 0 0;
}

.darkHeader {
  background-color: black;
  border-bottom-color: red;
  border-bottom-width: 1px;
}

.rightButtons {
  display: flex;
  margin-top: 1em;
}

.unreadAlertCount {
  color: white;
  background: red;
  border: 1px solid darkred;
  border-radius: 20px;
  margin-left: 0.5em;
  padding: 3px;
}