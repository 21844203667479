/*           Font Smoothing      */
body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  @include themed() {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-weight: t($font-weight-normal);
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  @include themed() {
    font-weight: t($font-weight-light);
    margin: t($margin-large-vertical) 0 t($margin-base-vertical);
  }
}

h1,
.h1 {
  @include themed() {
    font-size: t($font-size-h1);
    color: t($text-color);
  }
}
h2,
.h2 {
  @include themed() {
    font-size: t($font-size-h2);
  }
}
h3,
.h3 {
  @include themed() {
    font-size: t($font-size-h3);
    margin: 20px 0 10px;
  }
}
h4,
.h4 {
  @include themed() {
    font-size: t($font-size-h4);
    line-height: 30px;
  }
}
h5,
.h5 {
  @include themed() {
    font-size: t($font-size-h5);
    margin-bottom: 15px;
  }
}
h6,
.h6 {
  @include themed() {
    font-size: t($font-size-h6);
    font-weight: t($font-weight-bold);
    text-transform: uppercase;
  }
}
p {
  @include themed() {
    font-size: t($font-paragraph);
    line-height: t($line-height-general);
  }
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  @include themed() {
    color: t($dark-gray);
    font-weight: t($font-weight-light);
    line-height: t($line-height-general);
  }
}

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 60%;
}

h1 .subtitle {
  @include themed() {
    display: block;
    margin: 0 0 t($margin-large-vertical);
  }
}

.text-muted {
  @include themed() {
    color: t($dark-gray);
  }
}
.text-primary,
.text-primary:hover {
  @include themed() {
    color: t($primary-states-color) !important;
  }
}
.text-info,
.text-info:hover {
  @include themed() {
    color: t($info-color) !important;
  }
}
.text-success,
.text-success:hover {
  @include themed() {
    color: t($success-color) !important;
  }
}
.text-warning,
.text-warning:hover {
  @include themed() {
    color: t($warning-color) !important;
  }
}
.text-danger,
.text-danger:hover {
  @include themed() {
    color: t($danger-color) !important;
  }
}
