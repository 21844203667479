.root {
    font-size: xx-large;
}

.items {
    display: flex;
    flex-wrap: wrap;
}

.item {
    margin: 0 0 7px;
    padding: 12px 40px;
    border-radius: 4px;
    cursor: pointer;
    background: #1DC7EA;
    border: 1px solid transparent;
    font-size: 18px;
    color: #fff;
}

.container {
    display: flex;
    position: relative;
    margin-right: 8px;
}

.container :global(.fa) {
    position: absolute;
    font-size: 16px;
    background: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    line-height: 18px;
    top: -5px;
    border-width: 1px;
    border-color: gray;
    border-style: solid;
}

.container :global(.fa-times-circle) {
    right: -5px;
    color: red;
}

.container :global(.fa-pencil) {
    right: 15px;
    color: blue;
}

.delete {
    -webkit-align-self: flex-start;
    align-self: flex-start;
    background-color: #fff;
    margin-left: -20px;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    height: 18px;
}

:global(.swatches-picker) svg {
    height: 20px !important;
    position: absolute;
    top: 2px;
}
