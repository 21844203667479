.root {
}

.filterLabel {
    margin-left: 20px;
    margin-right: 10px;
}

.filterControl {
    display: inline-block;
    width: auto;
    min-width: 10em;
}

.filterAutoDetectionControl {
    min-width: 25em;
}

.filterGroup {
    margin-top: 10px;
}

.filterButton:global(.btn) {
    margin-left: 3em;
    background-color: blue;
    color: white;
}

.filterExists:global(.btn) {
    background-color: red;
}
