.root {

}

.itemNote {
    margin-top: 1px;
    width: 100%;
    min-height: 1.5em;
    border: 1px solid #aaa;
    padding: 0.1em;
    background: white;
    color: black;
}

.itemOtherNote {
    background: lightgrey;
    color: black;
}

.user {
    font-size: large;
    margin-top: 5px;
}
