.dropdown-menu {
  @include themed() {
    visibility: hidden;
    margin: 0;
    padding: 0;
    border-radius: t($border-radius-extreme);
    display: block;
    z-index: 9000;
    position: absolute;

    @include opacity(0);
    @include box-shadow(t($dropdown-shadow));

    .select & {
      border-radius: t($border-radius-bottom);
      @include box-shadow(none);
      @include transform-origin(t($select-coordinates));
      @include transform-scale(1);
      @include transition(t($fast-transition-time), t($transition-linear));
      margin-top: -20px;
    }
    .select.open & {
      margin-top: -1px;
    }

    > li > a {
      padding: t($padding-base-vertical) t($padding-base-horizontal);
      color: $dw-li-a;

      img {
        margin-top: -3px;
      }
    }
    > li > a:focus {
      outline: 0 !important;
    }

    .btn-group.select & {
      min-width: 100%;
    }

    > li:first-child > a {
      border-top-left-radius: t($border-radius-extreme);
      border-top-right-radius: t($border-radius-extreme);
    }

    > li:last-child > a {
      border-bottom-left-radius: t($border-radius-extreme);
      border-bottom-right-radius: t($border-radius-extreme);
    }

    .select & > li:first-child > a {
      border-radius: 0;
      border-bottom: 0 none;
    }

    > li > a:hover,
    > li > a:focus {
      background-color: t($smoke-bg);
      color: t($dw-li-a);
      opacity: 1;
      text-decoration: none;
    }

    &.dropdown-blue > li > a:hover,
    &.dropdown-blue > li > a:focus {
      background-color: t($light-blue);
    }
    &.dropdown-azure > li > a:hover,
    &.dropdown-azure > li > a:focus {
      background-color: t($light-azure);
    }
    &.ct-green > li > a:hover,
    &.ct-green > li > a:focus {
      background-color: t($light-green);
    }
    &.dropdown-orange > li > a:hover,
    &.dropdown-orange > li > a:focus {
      background-color: t($light-orange);
    }
    &.dropdown-red > li > a:hover,
    &.dropdown-red > li > a:focus {
      background-color: t($light-red);
    }
  }
  .open & {
    @include opacity(1);
    visibility: visible;
  }
}
.dropdown-with-icons {
  > li > a {
    padding-left: 0px;
    line-height: 28px;
  }
  i {
    text-align: center;
    line-height: 28px;
    float: left;

    &[class^="pe-"] {
      font-size: 24px;
      width: 46px;
    }
    &[class^="fa"] {
      font-size: 14px;
      width: 38px;
    }
  }
}

//fix bug for the select items in btn-group
.btn-group.select {
  overflow: hidden;
}
.btn-group.select.open {
  overflow: visible;
}
