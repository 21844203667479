.btn {
  @include themed() {
    border-width: t($border-thick);
    background-color: t($info-color);
    font-weight: t($font-weight-normal);
    color: t($white-color);

    @include opacity(0.8);
    padding: t($padding-base-vertical) t($padding-base-horizontal);

    @include btn-styles(t($default-color), t($default-states-color));

    &:hover,
    &:focus {
      @include opacity(1);
      outline: 0 !important;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
      @include box-shadow(none);
      outline: 0 !important;
    }

    &.btn-icon {
      @include themed() {
        padding: t($padding-base-vertical);
      }
    }
  }
}

// Apply the mixin to the buttons
//.btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary {
  @include themed() {
    @include btn-styles(t($primary-color), t($primary-states-color));
  }
}
.btn-success {
  @include themed() {
    @include btn-styles(t($success-color), t($success-states-color));
  }
}
.btn-info {
  @include themed() {
    @include btn-styles(t($info-color), t($info-states-color));
    @include themed() {
      background-color: t($info-color);
      color: t($white-color);
      box-shadow: 0 0 15px t($white-color);
    }
  }
}
.btn-warning {
  @include themed() {
    @include btn-styles(t($warning-color), t($warning-states-color));
  }
}
.btn-danger {
  @include themed() {
    @include btn-styles(t($danger-color), t($danger-states-color));
  }
}
.btn-neutral {
  @include themed() {
    @include btn-styles(t($white-color), t($white-color));

    &:active,
    &.active,
    .open > &.dropdown-toggle {
      @include themed() {
        background-color: t($white-color);
        color: t($default-color);
      }
    }

    &.btn-fill,
    &.btn-fill:hover,
    &.btn-fill:focus {
      @include themed() {
        color: t($default-color);
      }
    }

    &.btn-simple:active,
    &.btn-simple.active {
      background-color: transparent;
    }
  }
}

.btn {
  @include themed() {
    &:disabled,
    &[disabled],
    &.disabled {
      @include opacity(0.5);
    }
  }
}

.btn-round {
  @include themed() {
    border-width: t($border-thin);
    border-radius: t($btn-round-radius) !important;
    padding: t($padding-round-vertical) t($padding-round-horizontal);

    &.btn-icon {
      padding: t($padding-round-vertical);
    }
  }
}

.btn-simple {
  @include themed() {
    border: t($none);
    font-size: t($font-size-medium);
    padding: t($padding-base-vertical) t($padding-base-horizontal);

    &.btn-icon {
      padding: t($padding-base-vertical);
    }
  }
}
.btn-lg {
  @include themed() {
    @include btn-size(
      t($padding-large-vertical),
      t($padding-large-horizontal),
      t($font-size-large),
      t($border-radius-large)
    );
    font-weight: t($font-weight-normal);
  }
}
.btn-sm {
  @include themed() {
    @include btn-size(
      t($padding-small-vertical),
      t($padding-small-horizontal),
      t($font-size-small),
      t($border-radius-small)
    );
  }
}
.btn-xs {
  @include themed() {
    @include btn-size(
      t($padding-xs-vertical),
      t($padding-xs-horizontal),
      t($font-size-small),
      t($border-radius-small)
    );
  }
}
.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}
.btn-group.select .btn {
  text-align: left;
}
.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}
