html {
  --ieTextColor: black;
}

/* // dark theme */
html[data-theme="dark"] {
  --ieTextColor: #f5deb3;
}
.root {
}

.buttons {
  display: flex;
  margin-bottom: 10px;
}

.button {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.header {
  font-size: large;
  margin-bottom: 10px;
  color: var(--ieTextColor);
}

.image {
  float: left;
  margin-right: 10px;
  margin-bottom: 3px;
}

.emptyImage {
  border: var(--ieTextColor) 1px solid;
  float: left;
  background-color: white;
}

.loadingImage {
  border: var(--ieTextColor) 1px solid;
  float: left;
  width: 300px;
  height: 300px;
  text-align: center;
  padding-top: 110px;
  font-size: larger;
}

.info {
  font-size: 15px;
  font-weight: bold;
}

.infoLabels {
}

.infoLiveLabels {
  clear: both;
}

.infoLiveLabel {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
}

.infoLiveLabelName {
  background: red;
  color: white;
  margin-right: 5px;
  padding: 5px 7px;
  border-radius: 10px;
}

.infoLiveLabelAdditionalInfo {
  background: yellow;
  color: black;
  font-size: 24px;
  font-weight: bold;
}

.infoAlert {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 24px;
}

.infoAlertError {
  background: red;
  color: white;
  font-size: 24px;
  padding: 5px 15px;
  border-radius: 10px;
  cursor: pointer;
}

.infoAlertInfo {
  background: green;
  color: seagreen;
  font-size: 24px;
  padding: 5px 15px;
  border-radius: 10px;
  cursor: pointer;
}